// Import VanillaTilt and gsap libraries
import VanillaTilt from "vanilla-tilt";
import { gsap } from "gsap";
import { Card } from "../../data";


const template = document.createElement("template");
template.innerHTML = `
<style>
.cover {
	background-color: #333;
	width: 80px;
	height: 55px;
	position: absolute;
	top: 0;
	overflow: hidden;
}

.flipper {
	border-top: 1px solid #333;
	background-color: #333;
	width: 90px;
	height: 55px;
	position: absolute;
	top: 50%;
	transform: rotateX(-180deg);
	transform-origin: top;
	overflow: hidden;
}

.flipper span.tickerTxt {
	top: -112%;
	transform: scaleY(-1);
	position: absolute;
}

.flipper span.tickerTxt.inverse {
	transform: scaleY(1);
	top: -112%;
}

.credit-card {
	border-radius: 16px;
	background: linear-gradient(45deg, #1be7ff, #833ab4);
	transform-style: preserve-3d;
	transition: 0.2s;
	background-image: url(__CARD1_URL__);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.card-image {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	position: absolute;
	top: 0;
	left: 0;
	transform: translateZ(1px);
}

.player-cutout,
.slab-cutout {
	transition: transform 500ms;
}

.player-cutout,
.player-cutout-shadow,
.slab-cutout,
.slab-cutout-shadow {
	position:absolute;
	top:0;
	left:0;
	width: 100%;
	height: 100%;
	transform: translateZ(15px);

	-webkit-mask-image: url(__CUTOUT_URL__);
	-webkit-mask-size: contain;
	-webkit-mask-image: url(__CUTOUT_URL__);
	mask-size: contain;
	mask-position: center;
	mask-repeat: no-repeat;

	background-image: url(__CARD1_URL__);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.player-cutout-shadow {
	position: absolute;
	top: 0;
	transform: translateZ(1px);
	background: rgba(0, 0, 0, 0.9);
	filter: brightness(0.5);
}

.slab-cutout, .slab-cutout-shadow {
	position: absolute;
	width: 100%;
	height: 100%;
	transform: translateZ(15px);

	-webkit-mask-image: url(__SLAB_CUTOUT_URL__);
	-webkit-mask-size: contain;
	-webkit-mask-image: url(__SLAB_CUTOUT_URL__);
	mask-size: contain;
	mask-position: center;
	mask-repeat: no-repeat;

	background-image: url(__CARD1_URL__);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}


.credit-card * {
	user-select: none;
}

.card-shadow {
	position: absolute;
	top: -0.2%;
	left: -0.2%;
	width: 100.4%;
	height: 100.4%;
	background: black;
	border-radius: 16px;
	transform: translateZ(-5px);
}

.luma-embed-container {
	border-radius: 16px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.luma-embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.title,
.title-shadow {
	font-size: 40px;
	top: -50px;
	width: 100%;
	text-align: center;
	position: absolute;
}

.title-shadow {
	transform: translateZ(1px);
	color: white;
}

.title {
	transform: translateZ(10px);
	color: black;
}

.highlighted {
	transform: translateZ(30px);
	filter: brightness(1.2);
}

.slab-cutout {
	pointer-events: none;
}

</style>
<div class="credit-card">
	<div class="luma-embed"></div>
	<div class="title"></div>
	<div class="title-shadow"></div>
	<div class="slab-cutout-shadow"></div>
	<div class="slab-cutout"></div>
	<div class="player-cutout-shadow"></div>
	<div class="player-cutout"></div>
</div>
`;

//template.innerHTML = template.innerHTML.replace(/__CARD1_URL__/g, card1Url);
//template.innerHTML = template.innerHTML.replace(/__CUTOUT_URL__/g, cutoutUrl);

class TiltCard extends HTMLElement {
	static get observedAttributes() {
		return ["data-cardurl", "data-cutouturl", "data-slaburl", "data-raw", "data-maxheight"];
	}

	creditCard: HTMLElement;
	playerCutout: HTMLElement;
	playerCutoutShadow: HTMLElement;
	slabCutout: HTMLElement;
	slabCutoutShadow: HTMLElement;

	rawData: Card;

	imageNaturalWidth: number = 0;
	imageNaturalHeight: number = 0;
	maxHeight: number;

	constructor() {
		super();
		this.attachShadow({ mode: "open" });
		this.shadowRoot.appendChild(template.content.cloneNode(true));

		this.creditCard = this.shadowRoot.querySelector(".credit-card");
		this.playerCutout = this.shadowRoot.querySelector(".player-cutout");
		this.playerCutoutShadow = this.shadowRoot.querySelector(".player-cutout-shadow");
		this.slabCutout = this.shadowRoot.querySelector(".slab-cutout");
		this.slabCutoutShadow = this.shadowRoot.querySelector(".slab-cutout-shadow");
	}

	connectedCallback() {
		const creditCard = this.shadowRoot.querySelector(".credit-card");
		const iframe = this.shadowRoot.querySelector(".luma-embed");
		const title = this.shadowRoot.querySelector(".title");
		const titleShadow = this.shadowRoot.querySelector(".title-shadow");

		if (!creditCard) {
			throw new Error("No credit card element found");
			return;
		}


		VanillaTilt.init(creditCard, {
			max: 25,
			gyroscope: true,
			perspective: 1400,
			easing: "cubic-bezier(.03,.98,.52,.99)",
			speed: 1200,
			glare: false,
			maxGlare: 0.05,
			scale: 1.07,
		});

		let transforms = [
			[0.3541570007801056, 0, -0.9351860284805298, 0, 0.027690572664141655, 0.999561607837677, 0.01048648077994585, 0, 0.9347759485244751, -0.029609695076942444, 0.3540017008781433, 0, 2.287477970123291, 1.1451492309570312, 1.2842024564743042, 1],
			[0.6412413716316223, 0, -0.7673392295837402, 0, -0.291083425283432, 0.9252568483352661, -0.2432492971420288, 0, 0.7099858522415161, 0.3793412446975708, 0.5933129787445068, 0, 1.2500770092010498, 0.6959248185157776, 1.1420931816101074, 1],
			[0.749516487121582, 0, -0.6619857549667358, 0, -0.06343931704759598, 0.9953976273536682, -0.07182754576206207, 0, 0.6589389443397522, 0.09583184123039246, 0.7460668683052063, 0, 3.252533197402954, 0.8985317945480347, 3.795732021331787, 1]
		];

		creditCard.addEventListener("tiltChange", function (event) {
			const tiltPercentageX = -event.detail.percentageX;
			const tiltPercentageY = event.detail.percentageY;

			const transform = transforms[0].map((value, index) => {
				if (index === 12) {
					return value + tiltPercentageX / 100;
				} else if (index === 13) {
					return value + tiltPercentageY / 100;
				} else {
					return value;
				}
			});
		});
	}

	attributeChangedCallback(name: string, oldValue: string, newValue: string) {
		this.render();
	}

	updateSize() {
		let width = this.imageNaturalWidth;
		let height = this.imageNaturalHeight
		let aspectRatio = width / height;

		if (width > 350) {
			width = 350;
			height = width / aspectRatio;
		}
		if (height > this.maxHeight) {
			height = this.maxHeight;
			width = height * aspectRatio;
		}

		this.creditCard.style.width = `${width}px`;
		this.creditCard.style.height = `${height}px`;
		//delete img element
	}

	render() {
		let _card1Url = this.getAttribute("data-cardurl") || this.defaultCardUrl;
		let _cutoutUrl = this.getAttribute("data-cutouturl") || this.defaultCutoutUrl;
		let _slabUrl = this.getAttribute("data-slaburl") || this.defaultSlabUrl;
		const _maxHeight = this.getAttribute("data-maxheight");
		if (_maxHeight) {
			this.maxHeight = parseInt(_maxHeight);
		} else {
			this.maxHeight = 500;
		}

		this.creditCard.style.backgroundImage = `url(${_card1Url})`;
		this.playerCutout.style.backgroundImage = `url(${_card1Url})`;
		this.playerCutoutShadow.style.backgroundImage = `url(${_card1Url})`;
		this.slabCutout.style.backgroundImage = `url(${_card1Url})`;
		this.slabCutoutShadow.style.backgroundImage = `url(${_card1Url})`;

		this.playerCutout.style.webkitMaskImage = `url(${_cutoutUrl})`;
		this.playerCutoutShadow.style.webkitMaskImage = `url(${_cutoutUrl})`;
		this.slabCutout.style.webkitMaskImage = `url(${_slabUrl})`;
		this.slabCutoutShadow.style.webkitMaskImage = `url(${_slabUrl})`;

		// Set the dimensions based on the _card1Url image.
		if (this.imageNaturalHeight === 0 || this.imageNaturalWidth === 0) {
			let img = new Image();
			img.onload = () => {
				this.imageNaturalHeight = img.naturalHeight;
				this.imageNaturalWidth = img.naturalWidth;
				this.updateSize();
			}
			img.src = _card1Url;
		} else {
			this.updateSize();
		}
	}

}

window.customElements.define("tilt-card", TiltCard);
